import { useAppDispatch } from "app/hooks";
import { PATH_NAME } from "constants/pathNames";
import { authActions } from "features";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import AvatarDefault from "../../assets/images/avatarDefault.jpg";

export const ProfileDropdown = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const onLogout = () => {
    dispatch(authActions.logOut());
  };

  return (
    <Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={AvatarDefault}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                Admin
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                Founder
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome Admin!</h6>
          <DropdownItem onClick={() => navigate(PATH_NAME.PROFILE)}>
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          <DropdownItem onClick={() => navigate(PATH_NAME.CHANGE_PASSWORD)}>
            <i className="ri-key-2-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Change Password</span>
          </DropdownItem>
          {/* <DropdownItem href={process.env.PUBLIC_URL + "/apps-chat"}>
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Messages</span>
          </DropdownItem> */}
          <DropdownItem onClick={onLogout}>
            <i className="ri-logout-box-r-line text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  );
};
