import { ColumnDef } from "@tanstack/react-table";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { BreadCrumb, LineCharts, TableContainer } from "components";
import { StatusOptions } from "constants/options";
import { PATH_NAME } from "constants/pathNames";
import {
  getCampaignResult,
  getTopSubscribersByProfit,
  getTopSubscribersBySales,
  getTotalAgencyClients,
  getTotalAgencySubscribers,
  getTotalCampaignProfitFromSubscribers,
  getTotalCampaignSalesFromSubscribers,
  getTotalCampaignsFromSubscribers,
  getTotalDIYPaidSubscribers,
  getTotalFreeTrialSubscribers,
  getTotalInactiveSubscribers,
  getTotalIntegrationSubscribers,
  getTotalNewSubscribers,
  getTotalPaidSubscribers,
  getTotalTerminatedSubscribers,
} from "features";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { CampaignType, ChartDataProps, OptionProps } from "types";
import { formatCurrency } from "utils";
import { exportColumns } from "./constants";
import { FilterProps } from "./types";

const Dashboard = () => {
  const { campaignResultList } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<CampaignType[]>([]);
  const [campaignTypeOptions, setCampaignTypeOptions] = useState<
    OptionProps[] | null
  >(null);
  const [filter, setFilter] = useState<FilterProps>({
    campaign_name: "",
    campaign_type: null,
    fromDate: "",
    toDate: "",
    status: null,
  });
  const [chartData, setChartData] = useState<ChartDataProps>({
    topSubscribersBySales: [],
    topSubscribersByProfit: [],
    totalNewSubscribers: [],
    totalTerminatedSubscribers: [],
    totalFreeTrialSubscribers: [],
    totalPaidSubscribers: [],
    totalDIYPaidSubscribers: [],
    totalIntegrationSubscribers: [],
    totalAgencySubscribers: [],
    totalAgencyClients: [],
    totalInactiveSubscribers: [],
    totalCampaignsFromSubscribers: [],
    totalCampaignSalesFromSubscribers: [],
    totalCampaignProfitFromSubscribers: [],
  });

  const columns: ColumnDef<CampaignType>[] = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Type",
        accessorKey: "campaign_type.name",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => row.original.campaign_type.name,
      },
      {
        header: "Start Date",
        accessorKey: "start_date",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => moment(row.original.start_date).format("MM/DD/YYYY"),
      },
      {
        header: "End Date",
        accessorKey: "end_date",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => moment(row.original.end_date).format("MM/DD/YYYY"),
      },
      {
        header: "Total Customer",
        accessorKey: "total_customer",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => Number(row.original.total_customer),
      },
      {
        header: "Total Sales",
        accessorKey: "total_sale",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => formatCurrency(row.original.total_sale),
      },
      {
        header: "Total Cost",
        accessorKey: "total_cost",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => formatCurrency(row.original.total_cost),
      },
      {
        header: "Average Sales per Customer",
        accessorKey: "average_spend_per_customer",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) =>
          formatCurrency(row.original.average_spend_per_customer),
      },
      {
        header: "Profit per Customer",
        accessorKey: "profit_per_customer",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => formatCurrency(row.original.profit_per_customer),
      },
    ],
    []
  );

  const onExportPDFDashBoard = () => {
    window.open(PATH_NAME.REPORT_DASHBOARD);
  };

  const convertArrayOfObjectsToCSV = (array: any) => {
    let result = "";

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    result += exportColumns.map((d) => d.label).join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item: any) => {
      let ctr = 0;
      exportColumns.forEach((column) => {
        if (ctr > 0) result += columnDelimiter;

        let key = column.value;
        let cellValue = "";

        let keySplits = key.split(".");
        keySplits.forEach((subKey: any) => {
          cellValue = cellValue ? cellValue[subKey] : item[subKey];
        });

        result += '"' + cellValue + '"';
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  };

  const onExportCSVDashboard = () => {
    let csv = convertArrayOfObjectsToCSV(campaignResultList);
    if (csv == null) return;

    const filename = "Export_Dashboard.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    let link = document.createElement("a");
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  const onExportPDFCampaignResult = () => {
    window.open(PATH_NAME.REPORT_CAMPAIGN_RESULT);
  };

  const onExportCSVCampaignResult = () => {
    let csv = convertArrayOfObjectsToCSV(campaignResultList);
    if (csv == null) return;

    const filename = "Export_Campaign_Result.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    let link = document.createElement("a");
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  const onApply = () => {
    let filteredData = campaignResultList;

    if (filter.campaign_name?.trim()) {
      filteredData = filteredData.filter((item: CampaignType) =>
        item.name
          .toLowerCase()
          .includes(filter.campaign_name.toLowerCase()?.trim())
      );
    }

    if (filter.campaign_type) {
      filteredData = filteredData.filter(
        (item: CampaignType) =>
          item.campaign_type.name?.toLowerCase() ===
          filter.campaign_type?.value?.toLowerCase()
      );
    }

    if (filter.status) {
      filteredData = filteredData.filter(
        (item: CampaignType) =>
          item.status.toLowerCase() === filter.status?.value?.toLowerCase()
      );
    }

    if (filter.fromDate) {
      filteredData = filteredData.filter((data: CampaignType) =>
        moment(filter.fromDate).isSameOrBefore(moment(data.start_date))
      );
    }

    if (filter.toDate) {
      filteredData = filteredData.filter((data: CampaignType) =>
        moment(filter.toDate).isSameOrAfter(moment(data.end_date))
      );
    }

    setData(filteredData);
  };

  const onReset = () => {
    setFilter({
      campaign_name: "",
      campaign_type: null,
      fromDate: "",
      toDate: "",
      status: null,
    });
    setData(campaignResultList);
  };

  const loadCharts = useCallback(async () => {
    const data = await dispatch(getTopSubscribersBySales()).unwrap();
    const data1 = await dispatch(getTopSubscribersByProfit()).unwrap();
    const data2 = await dispatch(getTotalNewSubscribers()).unwrap();
    const data3 = await dispatch(getTotalTerminatedSubscribers()).unwrap();
    const data4 = await dispatch(getTotalFreeTrialSubscribers()).unwrap();
    const data5 = await dispatch(getTotalPaidSubscribers()).unwrap();
    const data6 = await dispatch(getTotalDIYPaidSubscribers()).unwrap();
    const data7 = await dispatch(getTotalIntegrationSubscribers()).unwrap();
    const data8 = await dispatch(getTotalAgencySubscribers()).unwrap();
    const data9 = await dispatch(getTotalAgencyClients()).unwrap();
    const data10 = await dispatch(getTotalInactiveSubscribers()).unwrap();
    const data11 = await dispatch(getTotalCampaignsFromSubscribers()).unwrap();
    const data12 = await dispatch(
      getTotalCampaignSalesFromSubscribers()
    ).unwrap();
    const data13 = await dispatch(
      getTotalCampaignProfitFromSubscribers()
    ).unwrap();

    setChartData({
      topSubscribersBySales: data,
      topSubscribersByProfit: data1,
      totalNewSubscribers: data2,
      totalTerminatedSubscribers: data3,
      totalFreeTrialSubscribers: data4,
      totalPaidSubscribers: data5,
      totalDIYPaidSubscribers: data6,
      totalIntegrationSubscribers: data7,
      totalAgencySubscribers: data8,
      totalAgencyClients: data9,
      totalInactiveSubscribers: data10,
      totalCampaignsFromSubscribers: data11,
      totalCampaignSalesFromSubscribers: data12,
      totalCampaignProfitFromSubscribers: data13,
    });
  }, [dispatch]);

  const loadCampaignTypeOptions = useCallback(() => {
    let types: OptionProps[] = [];
    campaignResultList.forEach((item) => {
      if (!types.find((s) => s?.value === item.campaign_type.name)) {
        types.push({
          value: item.campaign_type.name,
          label: item.campaign_type.name,
        });
      }
    });
    setCampaignTypeOptions(types);
  }, [campaignResultList]);

  useEffect(() => {
    loadCampaignTypeOptions();
    setData(campaignResultList);
  }, [campaignResultList, loadCampaignTypeOptions]);

  useEffect(() => {
    dispatch(getCampaignResult());
  }, [dispatch]);

  useEffect(() => {
    loadCharts();
  }, [loadCharts]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Dashboard" />
        <Card>
          <CardBody>
            <div className="d-flex align-items-center justify-content-end gap-2">
              <FormGroup>
                <Button color="primary" onClick={onExportPDFDashBoard}>
                  <i className="ri-file-pdf-line me-2" />
                  Export PDF
                </Button>
              </FormGroup>
              <FormGroup onClick={onExportCSVDashboard}>
                <Button color="primary">
                  <i className="ri-file-excel-2-line me-2" />
                  Export CSV
                </Button>
              </FormGroup>
            </div>
            <Row>
              <Col xs={12} lg={4}>
                <FormGroup>
                  <Label>Campaign Name</Label>
                  <div className="form-icon right">
                    <Input
                      placeholder="Search by campaign name"
                      value={filter.campaign_name}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          campaign_name: e.target.value,
                        })
                      }
                    />
                    <i className="ri-search-line" />
                  </div>
                </FormGroup>
              </Col>
              <Col xs={12} lg={4}>
                <FormGroup>
                  <Label>Campaign Type</Label>
                  <Select
                    options={campaignTypeOptions}
                    value={filter.campaign_type}
                    placeholder="Select campaign type"
                    onChange={(item: OptionProps) => {
                      setFilter({
                        ...filter,
                        campaign_type: item,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} lg={4}>
                <FormGroup>
                  <Label>Status</Label>
                  <Select
                    options={StatusOptions}
                    value={filter.status}
                    placeholder="Select status"
                    onChange={(item: OptionProps) => {
                      setFilter({
                        ...filter,
                        status: item,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} lg={4}>
                <FormGroup>
                  <Label>From Date</Label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      dateFormat: "d/m/Y",
                    }}
                    placeholder="From Date"
                    value={filter.fromDate}
                    onChange={(date) => {
                      console.log("from date", date);
                      setFilter({
                        ...filter,
                        fromDate: date[0],
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} lg={4}>
                <FormGroup>
                  <Label>To Date</Label>
                  <Flatpickr
                    className="form-control"
                    options={{
                      dateFormat: "d/m/Y",
                    }}
                    placeholder="To Date"
                    value={filter.toDate}
                    onChange={(date) => {
                      console.log("from date", date);
                      setFilter({
                        ...filter,
                        toDate: date[0],
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} lg={3} className="mt-auto">
                <FormGroup>
                  <Button color="primary" onClick={onApply}>
                    Apply
                  </Button>
                  <Button color="light" className="ms-2" onClick={onReset}>
                    Reset
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <h4 className="card-title mb-0 flex-grow-1">
                  Campaign Result Summary
                </h4>
              </Col>
              <Col xs={12} md={6}>
                <div className="d-flex align-items-center justify-content-end gap-2">
                  <FormGroup className="custom-form-group">
                    <Button color="primary" onClick={onExportPDFCampaignResult}>
                      <i className="ri-file-pdf-line me-2" />
                      Export PDF
                    </Button>
                  </FormGroup>
                  <FormGroup
                    className="custom-form-group"
                    onClick={onExportCSVCampaignResult}
                  >
                    <Button color="primary">
                      <i className="ri-file-excel-2-line me-2" />
                      Export CSV
                    </Button>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <TableContainer
              columns={columns || []}
              data={data || []}
              customPageSize={10}
              tableClass="table-centered align-middle table-nowrap mb-0"
              theadClass="text-muted table-light"
              divClass="table-responsive"
            />
          </CardBody>
        </Card>

        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Top Subscribers by Sales</h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.topSubscribersBySales} />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Top Subscribers by Profit</h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.topSubscribersByProfit} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total New Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.totalNewSubscribers} />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Terminated Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.totalTerminatedSubscribers} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Free Trial Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.totalFreeTrialSubscribers} />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Paid Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.totalPaidSubscribers} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total DIY Paid Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.totalDIYPaidSubscribers} />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Integration Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.totalIntegrationSubscribers} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Agency Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.totalAgencySubscribers} />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Agency Clients per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.totalAgencyClients} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Inactive Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.totalInactiveSubscribers} />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Campaigns from Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts data={chartData.totalCampaignsFromSubscribers} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Campaign Sales from Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts
                  data={chartData.totalCampaignSalesFromSubscribers}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Campaign Profit from Subscribers per Month (YTD)
                </h4>
              </CardHeader>
              <CardBody>
                <LineCharts
                  data={chartData.totalCampaignProfitFromSubscribers}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
