import { useAppDispatch } from "app/hooks";
import { BreadCrumb } from "components";
import { PackageKeyOptions } from "constants/options";
import { PATH_NAME } from "constants/pathNames";
import {
  createSubscription,
  getSubscriptionDetails,
  updateSubscription,
} from "features";
import { Formik, FormikProps } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { OptionProps, SubscriptionType } from "types";
import * as Yup from "yup";

const CAESubscriptionManagement = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const formikRef = useRef<FormikProps<SubscriptionType>>(null);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [isSubscribePlan, setIsSubscribePlan] = useState(false);

  const getSubscriptionInformation = useCallback(
    async (id: string) => {
      try {
        const details = await dispatch(
          getSubscriptionDetails(Number(id))
        ).unwrap();

        const findPackageKey = PackageKeyOptions.find(
          (item) => item?.value === details.package_key
        );

        if (details.limit_campaigns === -1) {
          setIsUnlimited(true);
        }

        if (!details.disable_monthly || !details.disable_annual) {
          setIsSubscribePlan(true);
        }

        formikRef.current?.setValues({
          name: details.name,
          limit_campaigns: details.limit_campaigns,
          is_trial_allowed: details.is_trial_allowed,
          status_in_words: details.status_in_words,
          access_dashboard: details.access_dashboard,
          access_campaign: details.access_campaign,
          access_track_sale: details.access_track_sale,
          lifetime_price: details.lifetime_price,
          disable_monthly: details.disable_monthly,
          disable_annual: details.disable_annual,
          monthly_price: details.monthly_price,
          annually_price: details.annually_price,
          is_special: details.is_special,
          priority: details.priority,
          enable_assistant: details.enable_assistant,
          package_key: findPackageKey as OptionProps,
          product_id: details.product_id,
          monthly_price_id: details.monthly_price_id,
          annual_price_id: details.annual_price_id,
        });
      } catch (error: any) {
        console.log("error: ", error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (id) {
      getSubscriptionInformation(id);
    }
  }, [getSubscriptionInformation, id]);

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          limit_campaigns: 0,
          is_trial_allowed: false,
          status_in_words: "active",
          access_dashboard: false,
          access_campaign: false,
          access_track_sale: false,
          lifetime_price: 0,
          disable_monthly: false,
          disable_annual: false,
          monthly_price: 0,
          annually_price: 0,
          is_special: false,
          priority: 0,
          enable_assistant: false,
          package_key: null,
          product_id: 0,
          monthly_price_id: "",
          annual_price_id: "",
        }}
        enableReinitialize
        innerRef={formikRef}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Please fill out this field."),
          limit_campaigns: Yup.number().required("Please fill out this field."),
          lifetime_price: !isSubscribePlan
            ? Yup.number().required("Please fill out this field.")
            : Yup.number(),
          monthly_price: isSubscribePlan
            ? Yup.number().required("Please fill out this field.")
            : Yup.number(),
          annually_price: isSubscribePlan
            ? Yup.number().required("Please fill out this field.")
            : Yup.number(),
          package_key: Yup.object().required("Please fill out this field."),
        })}
        onSubmit={async (values) => {
          const formatValues = {
            ...values,
            package_key: (values.package_key as any)?.value,
            disable_monthly: isSubscribePlan ? values.disable_monthly : true,
            disable_annual: isSubscribePlan ? values.disable_annual : true,
          };

          try {
            if (id) {
              formatValues.id = Number(id);
              await dispatch(updateSubscription(formatValues)).unwrap();
            } else {
              await dispatch(createSubscription(formatValues)).unwrap();
            }
            navigate(PATH_NAME.SUBSCRIPTION_MANAGEMENT);
          } catch (error) {
            console.log("error: ", error);
          } finally {
            formikRef.current?.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleSubmit,
        }) => (
          <div className="page-content">
            <Container fluid>
              <BreadCrumb
                title={`${id ? "Edit" : "Create New"} Subscription`}
              />
              <Card>
                <CardBody>
                  <div className="d-flex flex-row justify-content-end mb-3">
                    <Button
                      color="light"
                      className="me-2"
                      onClick={() =>
                        navigate(PATH_NAME.SUBSCRIPTION_MANAGEMENT)
                      }
                      disabled={isSubmitting}
                    >
                      <span>
                        <i className="ri-forbid-2-line me-1" />
                        Cancel
                      </span>
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                    >
                      <span>
                        <i className="ri-add-line me-1" />
                        {id ? "Update" : "Save"}
                      </span>
                    </Button>
                  </div>
                  <Row className="g-3">
                    <Col xs={12} lg={6}>
                      <FormGroup>
                        <Label className="required">Subscription name</Label>
                        <Input
                          name="name"
                          placeholder="Subscription name"
                          value={values.name}
                          onChange={handleChange}
                        />
                      </FormGroup>
                      {errors.name && touched.name && (
                        <p className="text-danger mt-2">{errors.name}</p>
                      )}
                    </Col>
                    <Col xs={12} lg={5}>
                      <FormGroup>
                        <div className="d-flex align-items-center gap-5">
                          <Label className="required">No. of Campaigns</Label>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={isUnlimited}
                                onChange={() => {
                                  if (isUnlimited) {
                                    setIsUnlimited(false);
                                    setFieldValue("limit_campaigns", 0);
                                  } else {
                                    setIsUnlimited(true);
                                    setFieldValue("limit_campaigns", -1);
                                  }
                                }}
                              />
                              Unlimited
                            </Label>
                          </FormGroup>
                        </div>
                        <Input
                          type={isUnlimited ? "text" : "number"}
                          name="limit_campaigns"
                          placeholder="Limit campaigns"
                          value={
                            isUnlimited ? "Unlimited" : values.limit_campaigns
                          }
                          onChange={handleChange}
                          disabled={isUnlimited}
                        />
                        {errors.limit_campaigns && touched.limit_campaigns && (
                          <p className="text-danger mt-2">
                            {errors.limit_campaigns}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={12} lg={6}>
                      <FormGroup>
                        <Label className="me-4" for="check-trial">
                          Included Trial Plan?
                        </Label>
                        <Input
                          id="check-trial"
                          type="checkbox"
                          name="is_trial_allowed"
                          checked={values.is_trial_allowed}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} lg={6}>
                      <FormGroup className="d-flex flex-row">
                        <Label className="required me-4">Status</Label>
                        <div className="d-flex flex-row">
                          <FormGroup check className="me-3">
                            <Input
                              id="radio-1"
                              name="status_in_words"
                              type="radio"
                              checked={values.status_in_words === "active"}
                              onChange={() =>
                                setFieldValue("status_in_words", "active")
                              }
                            />
                            <Label for="radio-1" check>
                              Active
                            </Label>
                          </FormGroup>
                          <FormGroup check>
                            <Input
                              id="radio-2"
                              name="status_in_words"
                              type="radio"
                              checked={values.status_in_words === "inactive"}
                              onChange={() =>
                                setFieldValue("status_in_words", "inactive")
                              }
                            />{" "}
                            <Label for="radio-2" check>
                              Inactive
                            </Label>
                          </FormGroup>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="bg-dark-subtle my-3" style={{ height: 1 }} />
                  <h3 className="fw-bold text-center mb-4">Access Control</h3>
                  <Row className="d-flex flex-row justify-content-center">
                    <Col xs={3}>
                      <FormGroup className="d-flex flex-column align-items-center">
                        <Label for="check-access">Access Dashboard</Label>
                        <Input
                          id="check-access"
                          name="access_dashboard"
                          checked={values.access_dashboard}
                          type="checkbox"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup className="d-flex flex-column align-items-center">
                        <Label for="check-campaign">Access Campaign</Label>
                        <Input
                          id="check-campaign"
                          name="access_campaign"
                          checked={values.access_campaign}
                          type="checkbox"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={3}>
                      <FormGroup className="d-flex flex-column align-items-center">
                        <Label for="check-track-sales">
                          Access Track Sales
                        </Label>
                        <Input
                          id="check-track-sales"
                          name="access_track_sale"
                          checked={values.access_track_sale}
                          type="checkbox"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="bg-dark-subtle my-3" style={{ height: 1 }} />
                  <h3 className="fw-bold text-center mb-4">Purchase Options</h3>
                  <Row className="gap-5">
                    <Col xs={12} md={3}>
                      <FormGroup check className="me-3">
                        <Input
                          id="radio-3"
                          name="radio-option"
                          type="radio"
                          checked={!isSubscribePlan}
                          onChange={() => setIsSubscribePlan(false)}
                        />
                        <Label for="radio-3" check>
                          One-time purchase
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col xs={12} lg={8}>
                      {!isSubscribePlan && (
                        <FormGroup>
                          <Label>Price</Label>
                          <Input
                            type="number"
                            name="lifetime_price"
                            placeholder="Lifetime price"
                            value={values.lifetime_price}
                            onChange={handleChange}
                          />
                          {errors.lifetime_price && touched.lifetime_price && (
                            <p className="text-danger mt-2">
                              {errors.lifetime_price}
                            </p>
                          )}
                        </FormGroup>
                        // <FormGroup>
                        //   <Label>
                        //     <Input
                        //       type="number"
                        //       name="lifetime_price"
                        //       placeholder="Lifetime price"
                        //       value={values.lifetime_price}
                        //       onChange={handleChange}
                        //     />
                        //     Price
                        //   </Label>
                        //   {errors.lifetime_price && touched.lifetime_price && (
                        //     <p className="text-danger mt-2">
                        //       {errors.lifetime_price}
                        //     </p>
                        //   )}
                        // </FormGroup>
                      )}
                    </Col>
                    <Col xs={12} md={3}>
                      <FormGroup check className="me-3">
                        <Input
                          id="radio-4"
                          name="radio-option"
                          type="radio"
                          checked={isSubscribePlan}
                          onChange={() => {
                            setIsSubscribePlan(true);
                            setFieldValue("disable_monthly", false);
                            setFieldValue("disable_annual", false);
                          }}
                        />
                        <Label for="radio-4" check>
                          Subscribe plan
                        </Label>
                      </FormGroup>
                    </Col>
                    {isSubscribePlan && (
                      <Col xs={12} lg={8}>
                        <Row className="align-items-center">
                          <Col xs={12} lg={6}>
                            <FormGroup>
                              <Label>
                                <Input
                                  name="disable_monthly"
                                  type="checkbox"
                                  className="me-4"
                                  defaultChecked={!values.disable_monthly}
                                  checked={!values.disable_monthly}
                                  onChange={() =>
                                    setFieldValue(
                                      "disable_monthly",
                                      !values.disable_monthly
                                    )
                                  }
                                />
                                Monthly
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col xs={12} lg={6}>
                            <FormGroup>
                              <Label>Price</Label>
                              <Input
                                type="number"
                                name="monthly_price"
                                placeholder="Monthly Price"
                                value={values.monthly_price}
                                onChange={handleChange}
                              />
                              {errors.monthly_price &&
                                touched.monthly_price && (
                                  <p className="text-danger mt-2">
                                    {errors.monthly_price}
                                  </p>
                                )}
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>
                                <Input
                                  type="checkbox"
                                  name="disable_annual"
                                  className="me-4"
                                  defaultChecked={!values.disable_annual}
                                  checked={!values.disable_annual}
                                  onChange={() =>
                                    setFieldValue(
                                      "disable_annual",
                                      !values.disable_annual
                                    )
                                  }
                                />
                                Yearly
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col xs={12} lg={6}>
                            <FormGroup>
                              <Label>Price</Label>
                              <Input
                                type="number"
                                name="annually_price"
                                placeholder="Yearly Price"
                                value={values.annually_price}
                                onChange={handleChange}
                              />
                              {errors.annually_price &&
                                touched.annually_price && (
                                  <p className="text-danger mt-2">
                                    {errors.annually_price}
                                  </p>
                                )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>

                  <div className="bg-dark-subtle my-3" style={{ height: 1 }} />
                  <h3 className="fw-bold text-center mb-4">
                    Stripe Payment Settings
                  </h3>
                  <FormGroup className="d-flex flex-row">
                    <Label className="me-4" for="check-package">
                      Is Special Package
                    </Label>
                    <Input
                      type="checkbox"
                      id="check-package"
                      name="is_special"
                      checked={values.is_special}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row">
                    <Label style={{ marginRight: 35 }} for="enable_assistant">
                      Enable Assistant
                    </Label>
                    <Input
                      type="checkbox"
                      id="enable_assistant"
                      name="enable_assistant"
                      checked={values.enable_assistant}
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Priority</Label>
                    <Input
                      type="number"
                      name="priority"
                      value={values.priority}
                      onChange={handleChange}
                      placeholder="Priority"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Package Key</Label>
                    <Select
                      options={PackageKeyOptions}
                      value={values.package_key}
                      placeholder="Select Package Key"
                      onChange={(item: OptionProps) =>
                        setFieldValue("package_key", item)
                      }
                    />
                    {errors.package_key && touched.package_key && (
                      <p className="text-danger mt-2">{errors.package_key}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Stripe Product ID</Label>
                    <Input
                      name="product_id"
                      value={values.product_id}
                      onChange={handleChange}
                      placeholder="Stripe Product ID"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Stripe Monthly Price ID</Label>
                    <Input
                      name="monthly_price_id"
                      value={values.monthly_price_id}
                      onChange={handleChange}
                      placeholder="Stripe Monthly Price ID"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Stripe Annual Price ID</Label>
                    <Input
                      name="annual_price_id"
                      value={values.annual_price_id}
                      onChange={handleChange}
                      placeholder="Stripe Annual Price ID"
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Container>
          </div>
        )}
      </Formik>
    </>
  );
};

export default CAESubscriptionManagement;
