import { PATH_NAME } from "constants/pathNames";
import { useFormik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";

const Register = () => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCFPassword, setShowCFPassword] = useState(false);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email")
        .required("Please fill out this field."),
      password: Yup.string().required("Please fill out this field."),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords do not match.")
        .required("Please fill out this field."),
    }),
    onSubmit: () => {
      setLoader(true);
      setError("");
    },
  });

  return (
    <div
      className="auth-page-content bg-primary vh-100"
      style={{ paddingTop: 150 }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className="text-center mt-2 mb-3">
                  <h5 className="text-primary">Create New Account</h5>
                </div>
                {error && <Alert color="danger">{error}</Alert>}
                {/* {success && (
                  <Alert color="success">
                    Register User Successfully and Your Redirect To Login
                    Page...
                  </Alert>
                )} */}
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  className="needs-validation"
                >
                  <div className="mb-3">
                    <Label className="required">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Enter email address"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email && (
                      <FormFeedback type="invalid">
                        <div>{validation.errors.email}</div>
                      </FormFeedback>
                    )}
                  </div>

                  <div className="mb-3">
                    <Label className="required">Password</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password}
                      />
                      {validation.touched.password &&
                        validation.errors.password && (
                          <p className="text-danger mt-2">
                            {validation.errors.password}
                          </p>
                        )}
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                        onClick={() => setShowPassword(!showPassword)}
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </button>
                    </div>
                  </div>

                  <div className="mb-2">
                    <Label className="required">Confirm Password</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input
                        name="confirm_password"
                        type={showCFPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirm_password}
                      />
                      {validation.touched.confirm_password &&
                        validation.errors.confirm_password && (
                          <p className="text-danger mt-2">
                            {validation.errors.confirm_password}
                          </p>
                        )}
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                        onClick={() => setShowCFPassword(!showCFPassword)}
                        type="button"
                        id="password-addon"
                      >
                        <i
                          className={`${
                            showCFPassword ? "ri-eye-off-fill" : "ri-eye-fill"
                          }  align-middle`}
                        ></i>
                      </button>
                    </div>
                  </div>

                  <div className="mb-4">
                    <p className="mb-0 fs-12 text-muted fst-italic">
                      By registering you agree to the Maralytics{" "}
                      <Link
                        to="#"
                        className="text-primary text-decoration-underline fst-normal fw-medium"
                      >
                        Terms of Use
                      </Link>
                    </p>
                  </div>

                  <div className="mt-4">
                    <Button
                      color="primary"
                      className="w-100"
                      type="submit"
                      disabled={loader && true}
                    >
                      {loader && <Spinner size="sm" className="me-2" />}
                      Sign Up
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <div className="mt-4 text-center">
              <p className="mb-0 text-white">
                Already have an account ?{" "}
                <Link
                  to={PATH_NAME.LOGIN}
                  className="fw-semibold text-white text-decoration-underline"
                >
                  SignIn
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
