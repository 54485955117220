import { useAppDispatch } from "app/hooks";
import { BreadCrumb } from "components";
import { PATH_NAME } from "constants/pathNames";
import {
  createRedemption,
  getRedemptionDetails,
  getSubscriptions,
  updateRedemption,
} from "features";
import { Formik, FormikProps } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { OptionProps } from "types";
import * as Yup from "yup";

type InnerProps = {
  id?: number;
  promotion_name: string;
  start_date: Date | string;
  end_date: Date | string;
  package_id: null | OptionProps | string;
  price_id: string;
  auto_generate: boolean;
  number_of_codes?: number;
};

const CAERedemptionManagement = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const fileRef = useRef<HTMLInputElement>(null);
  const formikRef = useRef<FormikProps<InnerProps>>(null);
  const [subscriptionOptions, setSubscriptionOptions] = useState<OptionProps[]>(
    []
  );

  const getSubscriptionOptions = useCallback(async () => {
    try {
      const subscriptions = await dispatch(getSubscriptions()).unwrap();
      setSubscriptionOptions(
        subscriptions.map((item) => ({
          label: item.name,
          value: item.id?.toString() as string,
        }))
      );
    } catch (error: any) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const getRedemptionInformation = useCallback(
    async (id: string) => {
      try {
        const details = await dispatch(
          getRedemptionDetails(Number(id))
        ).unwrap();

        const findPackageId = subscriptionOptions.find(
          (item) => Number(item?.value) === details.package_id
        );

        formikRef.current?.setValues({
          id: details.id,
          promotion_name: details.promotion_name,
          package_id: findPackageId as OptionProps,
          start_date: details.created_at,
          end_date: details.end_date,
          auto_generate: false,
          price_id: details.price_id,
          number_of_codes: details.total_code,
        });
      } catch (error: any) {
        console.log("error: ", error);
      }
    },
    [dispatch, subscriptionOptions]
  );

  useEffect(() => {
    if (id) {
      getRedemptionInformation(id);
    }
  }, [getRedemptionInformation, id, subscriptionOptions]);

  useEffect(() => {
    getSubscriptionOptions();
  }, [getSubscriptionOptions]);

  return (
    <Formik
      initialValues={{
        promotion_name: "",
        start_date: "",
        end_date: "",
        price_id: "",
        package_id: null,
        auto_generate: false,
        number_of_codes: 0,
      }}
      enableReinitialize
      innerRef={formikRef}
      validationSchema={Yup.object().shape({
        promotion_name: Yup.string().required("Please fill out this field."),
        package_id: Yup.object().required("Please fill out this field."),
        start_date: Yup.string().required("Please fill out this field."),
        end_date: Yup.string().required("Please fill out this field."),
        number_of_codes: Yup.number().required("Please fill out this field."),
      })}
      onSubmit={async (values) => {
        const formatValues = {
          ...values,
          package_id: Number((values.package_id as any)?.value),
        };

        try {
          if (id) {
            formatValues.id = Number(id);
            await dispatch(updateRedemption(formatValues)).unwrap();
          } else {
            await dispatch(createRedemption(formatValues)).unwrap();
          }
          navigate(PATH_NAME.REDEMPTION_MANAGEMENT);
        } catch (error) {
          console.log("error: ", error);
        } finally {
          formikRef.current?.setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={`${id ? "Edit" : "Create New"} Promotion`} />
            <Card>
              <CardBody>
                <Row className="g-3">
                  <Col xs={12} lg={8}>
                    <FormGroup>
                      <Label className="required">Promotion Name</Label>
                      <Input
                        name="promotion_name"
                        placeholder="Promotion Name"
                        value={values.promotion_name}
                        onChange={handleChange}
                      />
                      {errors.promotion_name && touched.promotion_name && (
                        <p className="text-danger mt-2">
                          {errors.promotion_name}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={4}>
                    <FormGroup>
                      <Label className="required">Apply Subscription</Label>
                      <Select
                        options={subscriptionOptions}
                        value={values.package_id}
                        placeholder="Select Subscription"
                        onChange={(item: OptionProps) =>
                          setFieldValue("package_id", item)
                        }
                      />
                      {errors.package_id && touched.package_id && (
                        <p className="text-danger mt-2">{errors.package_id}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={4}>
                    <FormGroup>
                      <Label>Start Date</Label>
                      <Flatpickr
                        name="start_date"
                        className="form-control"
                        options={{
                          dateFormat: "d/m/Y",
                        }}
                        placeholder="Start Date"
                        value={values.start_date}
                        onChange={(date) => {
                          setFieldValue("start_date", date[0]);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={4}>
                    <FormGroup>
                      <Label>Finish Date</Label>
                      <Flatpickr
                        name="end_date"
                        className="form-control"
                        options={{
                          dateFormat: "d/m/Y",
                        }}
                        placeholder="Finish Date"
                        value={values.end_date}
                        onChange={(date) => {
                          setFieldValue("end_date", date[0]);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={4}>
                    <FormGroup>
                      <Label>Stripe Price ID</Label>
                      <Input
                        name="price_id"
                        placeholder="Stripe Price ID"
                        value={values.price_id}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={4}>
                    <FormGroup>
                      <Label>Import File</Label>
                      <Button
                        color="primary"
                        className="w-100"
                        onClick={() => fileRef.current?.click()}
                      >
                        <i className="ri-upload-cloud-2-line me-1" />
                        Select File
                      </Button>
                      <input
                        type="file"
                        className="d-none"
                        id="file"
                        accept=".csv"
                        ref={fileRef}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={4}>
                    <FormGroup>
                      <Label className="mt-lg-4">
                        <Input
                          type="checkbox"
                          className="me-2"
                          defaultChecked={values.auto_generate}
                          onChange={() => {
                            setFieldValue(
                              "auto_generate",
                              !values.auto_generate
                            );
                          }}
                        />
                        Auto generate codes
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={4}>
                    <FormGroup>
                      <Label>Number Of Codes</Label>
                      <Input
                        type="number"
                        min={0}
                        name="number_of_codes"
                        value={values.number_of_codes}
                        onChange={handleChange}
                      />
                      {errors.number_of_codes && touched.number_of_codes && (
                        <p className="text-danger mt-2">
                          {errors.number_of_codes}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={12} className="text-end">
                    <Button
                      color="light"
                      className="me-2"
                      onClick={() => navigate(PATH_NAME.REDEMPTION_MANAGEMENT)}
                      disabled={isSubmitting}
                    >
                      <span>
                        <i className="ri-forbid-2-line me-1" />
                        Cancel
                      </span>
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                    >
                      <span>
                        <i className="ri-add-line me-1" />
                        {id ? "Update" : "Create"}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </div>
      )}
    </Formik>
  );
};

export default CAERedemptionManagement;
