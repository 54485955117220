import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createUserAPI,
  deleteUserAPI,
  disableUserAPI,
  enableUserAPI,
  getUserDetailsAPI,
  getUsersAPI,
  updateUserAPI,
} from "services";
import { CreateUserFormType, UpdateUserFormType } from "types";

export const getUsers = createAsyncThunk("user/getUsers", async () => {
  try {
    const { data } = await getUsersAPI();
    return data;
  } catch (error: any) {
    console.log("error: ", error);
    throw new Error(error.message);
  }
});

export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async (id: number) => {
    try {
      const { data } = await getUserDetailsAPI(id);
      return data;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (data: CreateUserFormType) => {
    try {
      await createUserAPI(data);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data: UpdateUserFormType) => {
    try {
      await updateUserAPI(data);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const enableUser = createAsyncThunk(
  "user/enableUser",
  async (id: number) => {
    try {
      await enableUserAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const disableUser = createAsyncThunk(
  "user/disableUser",
  async (id: number) => {
    try {
      await disableUserAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (id: number) => {
    try {
      await deleteUserAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);
