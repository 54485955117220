import { PATH_NAME } from "constants/pathNames";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

type Props = {
  title: string;
};

export const BreadCrumb = ({ title }: Props) => {
  const navigate = useNavigate();

  return (
    <Row>
      <Col xs={12}>
        <div className="page-title-box">
          <div className="mb-3">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li
                className="breadcrumb-item active cursor-pointer"
                onClick={() => window.location.reload()}
              >
                {title}
              </li>
            </ol>
          </div>
          <h4 className="mb-sm-0" onClick={() => navigate(PATH_NAME.DASHBOARD)}>
            {title}
          </h4>
        </div>
      </Col>
    </Row>
  );
};
