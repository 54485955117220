import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AvatarDefault from "../../assets/images/avatarDefault.jpg";

const UserProfile = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="mx-3">
                    <img
                      src={AvatarDefault}
                      alt=""
                      className="avatar-md rounded-circle img-thumbnail"
                    />
                  </div>
                  <div className="flex-grow-1 align-self-center">
                    <div className="text-muted">
                      <h5>Admin</h5>
                      <p className="mb-1">Email Id : admin@gmail.com</p>
                      <p className="mb-0">Id No : #{111}</p>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserProfile;
